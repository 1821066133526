import AmityClient, { ApiRegion, UserRepository, ConnectionStatus } from "@amityco/js-sdk";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { ChatChannel } from "./ChatChannel";
import { IChatConfig, IChatUser } from "./IChatConfig";
const apiKey = environment.AmityApiKey;

@Injectable({
    providedIn: 'root'
})
export class AmityService {

    private chatConfig: IChatConfig;
    private client = new AmityClient({ apiKey, apiRegion: ApiRegion.EU });
    private liveUser: any;
    public currentUser: IChatUser;
    private chatChannelList: ChatChannel[] = [];

    public chatChannelList$ = new Subject<ChatChannel[]>();
    public activeChatChannel$ = new Subject<ChatChannel>();
    public chatAvailable = false;    // Permet de masquer ou afficher le chat dans le menu;
    public chatAvailable$ = new BehaviorSubject<Boolean>(this.chatAvailable);    // Permet de masquer ou afficher le chat dans le menu;

    notifyChatAvailable() {
        this.chatAvailable$.next(this.chatAvailable);
    }

    public async login(chatConfig: IChatConfig) {
        console.log(`[AmityService.setupClient]`);

        this.chatConfig = chatConfig;
        if (!this.chatConfig || this.chatConfig?.active == false) {
            this.chatAvailable = false;
            this.notifyChatAvailable();
            return;
        }

        this.currentUser = chatConfig.currentUser;
        if (this.client.connectionStatus === ConnectionStatus.Connected) {
            this.client.removeAllListeners();
            this.client.unregisterSession();
        }

        // Recuperation du User
        return new Promise((resolve) => {
            this.client.on("connectionStatusChanged", async ({ newValue }) => {
                if (newValue === ConnectionStatus.Connected) {
                    // this.currentUserId = userId;

                    await this.getChatUser();
                    this.refreshChannelList(true);

                    this.chatAvailable = true;
                    this.notifyChatAvailable();
                    // this.chatAvailable$.next(true);

                    resolve(true);
                }
            });
            this.client.registerSession({ userId: chatConfig.currentUser.userId });
        });
    }

    public async logoff() {
        if (!this.client) {
            return;
        }

        this.client.removeAllListeners();
        this.client.unregisterSession();
        this.currentUser = null;
        this.chatChannelList = [];
        this.chatChannelList$.next(this.chatChannelList);
        this.activeChatChannel$.next(null);
        // this.client = null;
    }

    public refreshChannelList(force = false) {
        if (force) {
            for (let chatChannelConfig of this.chatConfig.configList) {
                let foundChannel = this.chatChannelList.find(cc => cc.channelId == chatChannelConfig.channelId);
                if (!foundChannel) {
                    let chatChannel = new ChatChannel(chatChannelConfig, this.chatChannelList.length + 1, this.chatConfig.currentUser.userId);
                    this.chatChannelList.push(chatChannel);
                }
            }
        }
        this.chatChannelList$.next(this.chatChannelList);
    }

    public async setActiveChatChannelByIndex(lastChatChannelIndex, chatChannelIndex) {
        if (lastChatChannelIndex) {
            const chatChannel = this.getChatChannelByIndex(lastChatChannelIndex);
            if (chatChannel.channel) {
                await chatChannel.stopReadingChannel();
            }
        }

        if (!chatChannelIndex) {
            this.activeChatChannel$.next(null);
            return;
        }

        const activeChatChannel = this.getChatChannelByIndex(chatChannelIndex);
        if (activeChatChannel.channel) {
            await activeChatChannel.startReadingChannel();
        }
        this.activeChatChannel$.next(activeChatChannel);
        activeChatChannel.setActive();
    }


    private getChatChannelByIndex(chatChannelIndex) {
        return this.chatChannelList.find(c => c.index == chatChannelIndex);
    }

    private async getChatUser() {
        return new Promise((resolve, reject) => {
            this.liveUser = UserRepository.getUser(this.currentUser.userId);
            this.liveUser.once('dataUpdated', model => {
                return resolve(model);
            });
            this.liveUser.once('dataError', error => {
                // console.error(`[AmityService.onUserError]`, error);
                return reject(error);
            });
        })
    }



    public sortChatChannelsByLastActivity() {

        if (this.chatChannelList.length) {
            const havingChannels = this.chatChannelList.filter(cc => cc.channel);
            const notHavingChannels = this.chatChannelList.filter(cc => !cc.channel);

            havingChannels.sort((a, b) => {
                const aHasChannel = a.channel;
                const bHasChannel = b.channel;

                if (aHasChannel && bHasChannel) {
                    return new Date(b.channel.lastActivity).getTime() - new Date(a.channel.lastActivity).getTime();
                } else {
                    if (!aHasChannel) {
                        return 1;
                    } else {
                        return -1;
                    }
                }
            });

            this.chatChannelList = havingChannels.concat(notHavingChannels);
            this.chatChannelList$.next(this.chatChannelList)
        }

    }

}