<app-notification></app-notification>
<app-loader></app-loader>
<app-pwa-install-modal class="{{domainName}}"></app-pwa-install-modal>
<div id="app" class="position-relative {{domainName}}">
    <router-outlet></router-outlet>
</div>
<app-update-version-modal></app-update-version-modal>

<div class="versionPwa {{domainName}}" (click)="checkUpdateForced()">
  v {{ version }}
</div>

<ng-template #tripSelector let-c="close" >
  <app-trip-selector-panel [class]="domainName"></app-trip-selector-panel>
</ng-template>