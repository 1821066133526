import { ElementRef, Injectable, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class CreateBookingService {

    constructor(private router: Router) {

    }
    private activeSubscription: any;
    public activeSubscriptionChanged$ = new BehaviorSubject<any>(null);

    private nextSubscription: any;
    public nextSubscriptionChanged$ = new BehaviorSubject<any>(null);

    private modalVisible = false;
    modalStatusChanged$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private bookingAvailable = false;
    bookingAvailable$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public setActiveSubscription(subscription) {

        this.activeSubscription = subscription;
        this.activeSubscriptionChanged$.next(this.activeSubscription);

        this.bookingAvailable = subscription && (subscription.suspended == false);
        this.bookingAvailable$.next(this.bookingAvailable);
    }

    public setNextSubscription(subscription) {

        this.nextSubscription = subscription;
        this.nextSubscriptionChanged$.next(this.nextSubscription);

    }

    public createBooking() {

        // if(!this.activeSubscription.deposit.valid){
        //     this.router.navigate(['my-profile']);
        //     return;
        // }
        // Gestion LPV1 : Directement sur create-booking
        const isLPV2 = this.activeSubscription.subscriptionType == 'LPV2';
        if (!isLPV2) {
            this.router.navigate(['create-booking']);
            return;
        }

        // Si accreditation unique, sur 1 seul bateau, et que le trip lié est MultiDay et pas de nextSubsciption
        let singleAcc = this.activeSubscription?.accreditations?.length == 1 ? this.activeSubscription?.accreditations[0] : null;
        if (singleAcc && singleAcc.boatId && singleAcc.trip && singleAcc.trip.multiDayBooking && !this.nextSubscription) {
            this.router.navigate(['create-long-booking', singleAcc.boatId, 'trip', singleAcc.trip.id, 'acc', singleAcc.id]);
            return;
        }

        const multiDayTripList = ( this.activeSubscription?.accreditations || []).filter(acc => !!acc.trip?.multiDayBooking);
        const nextMultiDayTripList = ( this.nextSubscription?.accreditations || []).filter(acc => !!acc.trip?.multiDayBooking);
        // Has au moins une acc. avec un trip Multiday
        if (multiDayTripList.length > 0 || nextMultiDayTripList.length > 0 ) {
            this.showTripSelector();
            return;
        } else {
            this.router.navigate(['create-booking']);
            return;
        }

    }

    private showTripSelector() {
        if (this.modalVisible) return;
        this.modalStatusChanged$.next(true);
        this.modalVisible = true;
    }

    public hideTripSelector(selectedAccreditation: any = null) {
        if (!this.modalVisible) return;
        this.modalStatusChanged$.next(false);
        this.modalVisible = false

        if (selectedAccreditation) {
            this.createBookingWithAccreditationSelected(selectedAccreditation);
            // this.router.navigate(['create-long-booking', selectedAccreditation.boatId, 'trip', selectedAccreditation.trip.id, 'acc', selectedAccreditation.id]);
        }
    }

    createBookingWithAccreditationSelected(singleAcc) {
        if (singleAcc && singleAcc.boatId && singleAcc.trip && singleAcc.trip.multiDayBooking) {
            this.router.navigate(['create-long-booking', singleAcc.boatId, 'trip', singleAcc.trip.id, 'acc', singleAcc.id]);
        } else {
            this.router.navigate(['create-booking']);
        }
    }

    notifyBookingAvailable() {
        this.bookingAvailable$.next(this.bookingAvailable);
    }



}
