<!-- <div class="fakeDiv">
    <button class="btn" (click)="fake()">Fake update</button>
</div> -->
<div class="updateAvailable {{domainName}}" *ngIf="visible">
    <div class="msgVersion">
        <fa-icon [icon]="['fas', 'exclamation-circle']"></fa-icon> {{ 'pwa.updateNewVersionInformation' | translate }}
    </div>
    <div class="btnInstall">
        <button class="btn btn-sm btn-light" (click)="reload()">Install</button>
    </div>
</div>