import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { catchError, tap } from 'rxjs/operators';
import { default as packageJson } from 'src/../package.json';
import { LocalStorageService } from 'angular-web-storage';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private localStorageService: LocalStorageService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const isAuthenticated = this.authenticationService.isUserAuthenticated();
    if (isAuthenticated) {

      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.authenticationService.getAccessToken()}`,
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          'x-nauticoncept-club-app-version': packageJson.version,
          "X-Timezone-Offset" : this.getTimezoneOffset(),
          "X-User-Lang" : this.getUserLanguage(),
          "X-Timezone" : this.getUserTimezone(),
        },
      });
    }

    return next.handle(request).pipe(
      tap((ev: HttpEvent<any>) => {
        // if (ev instanceof HttpResponse) {
        //   console.log('processing response', ev);
        // }
      }),
      catchError(response => {
        let { status, error } = response;
        if (response instanceof HttpErrorResponse) {
          //console.log('Processing http error', response);
          if (response.status == 401 && response.error == 'ERR_INVALID_SESSION') {
            this.authenticationService.logout();
          }
          if (401 == status && "ERROR_USER_NOT_ACTIVE" == error) {
            this.authenticationService.logout(); // Session expired
          }
          if (response.status == 403 && response.error == 'Forbidden') {
            this.authenticationService.logout();
          }
          if (response.status == 403 && response.error?.reasonCode == 'ERROR_NO_TENANT_ROLE') {
            this.authenticationService.logout();
          }
        }
        return throwError(response);
      })
    )
  }

  private getTimezoneOffset() : string {
 
		return( String( new Date().getTimezoneOffset() ) );
 
	}

  private getUserTimezone() : string {
 
    let timeOptions : any = Intl.DateTimeFormat().resolvedOptions();
		return( String( timeOptions.timeZone ) );
 
	}

  private getUserLanguage() : string {
		return this.localStorageService.get('lang') || 'fr';
	}

}
