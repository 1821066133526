<div class="trip-selector-container"  [ngClass]="{'closing' : closing}">
    <div class="half-top">&nbsp;</div>
    <div class="half-bottom" *ngIf="accreditionList && accreditionList.length && (!nextAccreditationList || nextAccreditationList.length==0)">
        
        <app-banner-section [value]="'Select.your.trip'"></app-banner-section>

        <div class="trip-selector-list" *ngIf="accreditionList">
            <div *ngFor="let acc of accreditionList">
                <button class="btn trip-selector-button" (click)="selectAccreditation(acc)">
                    <fa-icon [icon]="['fas', 'chevron-right']"></fa-icon>
                    <span>{{acc.name}}</span>
                </button>
            </div>

        </div>

    </div>
    <div class="half-bottom" *ngIf="accreditionList && accreditionList.length && nextAccreditationList && nextAccreditationList.length">

        <app-banner-section [value]="'Lp.show.active.susbscription'"></app-banner-section>

        <div class="trip-selector-list" *ngIf="accreditionList">
            <div *ngFor="let acc of accreditionList">
                <button class="btn trip-selector-button" (click)="selectAccreditation(acc)">
                    <fa-icon [icon]="['fas', 'chevron-right']"></fa-icon>
                    <span>{{acc.name}}</span>
                </button>
            </div>

        </div>

        <app-banner-section [value]="'Lp.show.next.susbscription'"></app-banner-section>

        <div class="trip-selector-list" *ngIf="nextAccreditationList">

            <div *ngFor="let acc of nextAccreditationList">
                <button class="btn trip-selector-button" (click)="selectAccreditation(acc)">
                    <fa-icon [icon]="['fas', 'chevron-right']"></fa-icon>
                    <span>{{acc.name}}</span>
                </button>
            </div>

            <!-- <div>
                <button class="btn subscription-selector-button" (click)="selectActiveSubscription()">
                    <span>{{ 'Lp.show.active.susbscription'  | translate }}</span>
                    <br>
                    <span class="sub-text">{{ 'Lp.susbscription.untilDate' | translate }} {{ nextSubscription.endDate | date: 'shortDate':undefined:locale }}</span>
                </button>
            </div> -->
           
        </div>

    </div>
    <div class="zone-cancel">
        <div>
            <button class="btn trip-selector-close-button" (click)="closeSelector()">
                <fa-icon [icon]="['fas', 'times']"></fa-icon>
            </button>
        </div>
    </div>
</div>