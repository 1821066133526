import { Subject } from "rxjs";
import { ChannelRepository, ChannelType, MessageRepository } from "@amityco/js-sdk";
import { IChatChannelConfig } from "./IChatConfig";

const CHAT_CHANNEL_NOT_EXISTS = 'not-exist';

export class ChatChannel {

    public messageList$ = new Subject<any[]>();
    public channel$ = new Subject<any>();
    public messageList: any[] = [];
    public channel: any;
    public messageLoaded = false;
    private liveMessageList: any;
    private liveChannel: any;
    private liveChannelMembers: any;

    public get channelName() { return this.chatChannelConfig.channelName; }
    public get channelId() { return this.chatChannelConfig.channelId; }

    constructor(public chatChannelConfig: IChatChannelConfig, public index: number, public currentUserId) {
        try {
            this.loadChannel();
            this.listChannelMembers();
            this.loadMessages();
        } catch (error) {
            console.error(error);
        }
    }

    private loadChannel() {
        this.liveChannel = ChannelRepository.getChannel(this.channelId);

        if (this.liveChannel.dataStatus == CHAT_CHANNEL_NOT_EXISTS) {
            return this.createChannel();
        }

        this.liveChannel.once('dataError', error => {
            // console.error(`[AmityService.onChannelError]`, this.channelId, error);
        });

        this.liveChannel.on('dataUpdated', channelUpdated => {
            // console.log(`${channelUpdated.unreadCount} message unread`);

            this.channel = channelUpdated;
            this.channel$.next(this.channel);
        });
    }

    private createChannel() {
        let channelData = {
            channelId: this.chatChannelConfig.channelId,
            type: ChannelType.Community,
            userIds: this.chatChannelConfig.userIdList,
            tags: this.chatChannelConfig.userIdList,
            displayName: this.chatChannelConfig.channelId
        }

        this.liveChannel = ChannelRepository.createChannel(channelData);
        this.liveChannel.once('dataUpdated', channelUpdated => {
            // console.log(`Channel dataUpdated`);
            this.channel = channelUpdated;
            this.channel$.next(channelUpdated);
            this.liveChannel.removeAllListeners('dataUpdated');
            this.liveChannel.on('dataUpdated', channelUpdated => {
                this.channel = channelUpdated;
                this.channel$.next(channelUpdated);
            })

            this.listChannelMembers();
            this.loadMessages();

        });
        // });
        this.liveChannel.once('dataError', error => {
            // console.error(`createChannel ${this.channelId} error`, error);
        });
    }

    private loadMessages() {
        this.liveMessageList = MessageRepository.queryMessages({ channelId: this.channelId });
        this.liveMessageList.on('dataUpdated', messageList => {
            // console.log(`Messages dataUpdated`);
            this.messageList = messageList;
            this.messageList$.next(messageList);
        });
        this.liveMessageList.once('dataError', error => {
            console.error(`liveMessageList error`, error);
        });
    }

    private async listChannelMembers() {
        this.liveChannelMembers = ChannelRepository.queryMembers({ channelId: this.channelId });
        this.liveChannelMembers.on('dataUpdated', async (channelMemberList: any[]) => {
            const foundCurrentUser = channelMemberList.find(u => u.userId == this.currentUserId);
            if (!foundCurrentUser) {
                await ChannelRepository.joinChannel({ channelId: this.channelId });
            }
        });

        this.liveChannelMembers.on('dataError', error => {
            // console.error(error);
        });
    }

    public sendMessage(text: string) {
        MessageRepository.createTextMessage({ channelId: this.channelId, text });
        ChannelRepository.startReading(this.channelId);
    }



    public async stopReadingChannel() {
        console.log(`stopReadingChannel ${this.channelId}`);
        await ChannelRepository.stopReading(this.channelId);
    }

    public async startReadingChannel() {
        console.log(`startReadingChannel ${this.channelId}`);
        await ChannelRepository.startReading(this.channelId);
    }

    // TODO : Delete
    setActive() {
        // Waiting next tick to ensure change detection
        window.setTimeout(() => {
            this.messageList$.next(this.messageList);
        }, 100);
    }

    getLastMessage() {
        if (this.messageList.length == 0) {
            return null;
        }
        const foundLastMessage = this.messageList[this.messageList.length - 1];
        const lastMessage = {
            user: foundLastMessage.user.userId == this.currentUserId ? 'YOU' : foundLastMessage.user.displayName,
            message: foundLastMessage.data.text
        }
        return lastMessage;
    }


}