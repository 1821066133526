import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { BehaviorSubject, concat, first, interval } from 'rxjs';
import { ApplicationRef, Injectable } from '@angular/core';

const CHECK_FOR_UPDATE_FREQUENCY = 60 * 1000 * 2; // 20 min pour le test

@Injectable({
    providedIn: 'root',
})
export class AppUpdateService {

    $updateInformation: BehaviorSubject<boolean> = new BehaviorSubject(false);


    constructor(
        private appRef: ApplicationRef,
        private updates: SwUpdate) {

    }

    setup() {
        this.updates.versionUpdates.subscribe((evt: VersionEvent) => this.handleVersionUpdateEvent(evt));
        // this.checkForUpdate();

        // Allow the app to stabilize first, before starting
        // polling for updates with `interval()`.
        const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true))
        // this.appRef.isStable.subscribe( isStable => {
        //     console.log('IsStable', isStable);
        // })
        this.appRef.isStable.pipe(first(isStable => isStable === true));
        const checkFrequency$ = interval(CHECK_FOR_UPDATE_FREQUENCY);
        // checkFrequency$.subscribe( check => {
        //     console.log('checkFrequency', check);
        //     this.checkForUpdate();
        // })
        const checkFrequencyOnceAppIsStable$ = concat(appIsStable$, checkFrequency$);
        checkFrequencyOnceAppIsStable$.subscribe(() => this.checkForUpdate);
    }

    checkForUpdate() {
        if (this.updates.isEnabled) {
            this.updates.checkForUpdate();
        }
    }

    handleVersionUpdateEvent(evt: VersionEvent) {
        switch (evt.type) {
            case 'VERSION_DETECTED':
            case 'NO_NEW_VERSION_DETECTED':
                break;
            case 'VERSION_READY':
                this.$updateInformation.next(true);
                break;
            case 'VERSION_INSTALLATION_FAILED':
                console.error(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
                break;
        }
    }

    installNewVersion() {
        document.location.reload();
    }

    fakeEvent() {
        let evt: VersionEvent = {
            type: 'VERSION_READY',
            currentVersion: {
                hash: '12345'
            },
            latestVersion: {
                hash: '123456'
            }
        };

        this.handleVersionUpdateEvent(evt);
    }
}