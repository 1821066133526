<div class="installApp" *ngIf="appService.$showInstallButton | async">
    <div class="androidClose closeIcon" (click)="hideAndroidInstallMessage()">
        <fa-icon [icon]="['fas', 'times-circle']"></fa-icon>
    </div>
    <button class="btn btn-primary text-center installButton" (click)='appService.installApp()'>
        <fa-icon [icon]="['fas', 'download']"></fa-icon>
        {{ 'pwa.installButton' | translate }}
    </button>
</div>

<div class="box arrow-bottom" *ngIf="showIPhonePrompt">
    <div class="messageFlex">
        <div class="iconapp">
            <img src="/api/club-members/icons?size=152x152" class="icon" alt="">
        </div>
        <div class="msgInstall">
            {{'pwa.installIphone.text1' | translate }}
            <img src="assets/icons/iphone-share.svg" alt="">
            {{'pwa.installIphone.text2' | translate }}
        </div>
        <div class="closeIcon" (click)="hideIPhoneInstallMessage()">
            <fa-icon [icon]="['fas', 'times-circle']"></fa-icon>
        </div>
    </div>
</div>

<div class="boxIpad arrow-top" *ngIf="showIPadPrompt">
    <div class="messageFlex">
        <div class="iconapp">
            <img src="/api/club-members/icons?size=152x152" class="icon" alt="">
        </div>
        <div class="msgInstall">
            {{'pwa.installIpad.text1' | translate }}
            <img src="assets/icons/iphone-share.svg" alt="">
            {{'pwa.installIphone.text2' | translate }}
        </div>
        <div class="closeIcon" (click)="hideIPadInstallMessage()">
            <fa-icon [icon]="['fas', 'times-circle']"></fa-icon>
        </div>
    </div>
</div>