import { Injectable } from '@angular/core';

class Notification {
  constructor(public text: string, public type: string, public reason = '') { }
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {

  TYPES = {
    default: 'primary',
    success: 'success',
    info: 'info',
    warning: 'warning',
    error: 'danger',
  };
  private _isDisplayed = false;
  private _notification: Notification = {
    text: '',
    type: this.TYPES.error,
    reason: '',
  };

  showNotification(notification: Notification, duration = 2500): void {
    this._notification = notification;
    this._isDisplayed = true;
    setTimeout(() => { this._isDisplayed = false; }, duration);
  }

  default(message: string): void {
    let notification = new Notification(message, this.TYPES.default)
    this.showNotification(notification);
  }

  success(message: string): void {
    let notification = new Notification(message, this.TYPES.success)
    this.showNotification(notification);
  }

  error(message?: string, reason: string = '', duration?:number): void {
    let _message = message || 'An.error.occured';
    let notification = new Notification(_message, this.TYPES.error, reason);
    this.showNotification(notification, duration);
  }

  info(message: string, duration?: number): void {
    let notification = new Notification(message, this.TYPES.info);
    this.showNotification(notification, duration);
  }

  warning(message: string): void {
    this.showNotification(new Notification(message, this.TYPES.warning));
  }

  get isDisplayed(): boolean {
    return this._isDisplayed;
  }

  get notification(): Notification {
    return this._notification;
  }
}
