import {  Component, HostListener, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'app-pwa-install-modal',
  templateUrl: './pwa-install-modal.component.html',
  styleUrls: ['./pwa-install-modal.component.scss']
})
export class PwaInstallModalComponent implements OnInit {

  constructor(public appService: AppService) { }

  showIPhonePrompt: boolean;
  showIPadPrompt: boolean;

  @HostListener('window:beforeinstallprompt', ['$event'])
  onBeforeinstallprompt(ev: Event) {
    this.appService.beforeInstallPrompt(ev);
  }

  @HostListener('window:appinstalled', ['$event'])
  onAppinstalled(ev: Event) {
    this.appService.appInstalled(ev);
  }

  ngOnInit() {
    this.appService.$showIPhoneInstallMessage.subscribe((val) => {this.showIPhonePrompt = val;});
    this.appService.$showIPadInstallMessage.subscribe((val) => {this.showIPadPrompt = val;});
    this.appService.setup();
  }

  hideIPhoneInstallMessage(){
    this.appService.$showIPhoneInstallMessage.next(false);
  }

  hideIPadInstallMessage(){
    this.appService.$showIPadInstallMessage.next(false);
  }
  hideAndroidInstallMessage(){
    this.appService.$showInstallButton.next(false);
  }
}
