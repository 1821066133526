import { Component } from '@angular/core';
import { AppUpdateService } from 'src/app/services/app-update.service';

@Component({
  selector: 'app-update-version-modal',
  templateUrl: './update-version-modal.component.html',
  styleUrls: ['./update-version-modal.component.scss']
})
export class UpdateVersionModalComponent {

  updateInformation: string = "";
  translateKeys = ['pwa.updateNewVersionInformation'];
  visible = true;

  constructor(private appUpdateService: AppUpdateService) {
    this.appUpdateService.$updateInformation.subscribe((updateAvailable: boolean) => { 
      this.visible = updateAvailable;
    });

    this.appUpdateService.setup();
  }

  reload() {
    this.appUpdateService.installNewVersion();
  }

  fake(){
    this.appUpdateService.fakeEvent();
  }

}
