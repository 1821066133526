import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsUserNotLoggedInGuard } from './guards/is-user-not-logged-in.guard';
import { IsUserLoggedInGuard } from './guards/is-user-logged-in.guard';

const routes: Routes = [
  {
    path: 'welcome',
    loadChildren: () => import(`./modules/authentication/authentication.module`).then(m => m.AuthenticationModule),
    canActivate: [IsUserNotLoggedInGuard]
  },
  {
    path: '',
    loadChildren: () => import(`./modules/ui/ui.module`).then(m => m.UiModule),
    canActivate: [IsUserLoggedInGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
