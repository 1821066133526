import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class IsUserLoggedInGuard  {
  constructor(
    private router: Router,
    private authenticationService : AuthenticationService
  ) { }


  async canActivate() {

    const accessTokenFromUrl = this._getAccessTokenFromUrl();
    
    if(accessTokenFromUrl){
      console.log('token provided')
      await this.authenticationService.setUser(accessTokenFromUrl)

      let routeWithoutParams = document.location.href.split('?accessToken')[0];
      document.location.href = routeWithoutParams;

    }

    const isUserAuthenticated = this.authenticationService.isUserAuthenticated();
    if (isUserAuthenticated) {
      return true;
    }
    this.router.navigate(['/welcome']);
    return false;
  }

  private _getAccessTokenFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('accessToken');
    return token;
  }
}
