import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { default as packageJson } from 'src/../package.json';
import { ExternalMedia } from '../models/ExternalMedia';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationApiService {
  constructor(
    private http: HttpClient,
  ) { }

  login(email: string, password: string) {
    let data = { email, password };
    let options: any = {};
    options.headers = { 'x-nauticoncept-club-app-version': packageJson.version };
    return this.http.post(`${environment.baseUrl}/auth/local/tenant`, data, options).toPromise();
  }

  public fetchCurrentUser(): Promise<any> {
    return this.http.get(`${environment.baseUrl}/club-members/identities/me`).toPromise();
  }

  public fetchCurrentUserWithAttachments(): Promise<any> {
    return this.http.get(`${environment.baseUrl}/club-members/identities/me?with-attachments=1`).toPromise();
  }

  public updateCurrentUser(user) {
    return this.http.put(`${environment.baseUrl}/club-members/identities/me`, user).toPromise();
  }

  public getCurrentUserSubscription() {
    return this.http.get(`${environment.baseUrl}/club-members/subscriptions`).toPromise();
  }

  public async getActiveSubscription() {

      let currentSubscription = await this.http.get<any>(`${environment.baseUrl}/club-members/subscriptions/active`).toPromise();
      if (currentSubscription.subscriptionType != 'LPV2') {
        currentSubscription.remainingHighSeasonCredits = currentSubscription.remainingHighSeasonCredits >= 0 ? currentSubscription.remainingHighSeasonCredits : 0;
        currentSubscription.remainingLowSeasonCredits = currentSubscription.remainingLowSeasonCredits >= 0 ? currentSubscription.remainingLowSeasonCredits : 0;
        currentSubscription.remainingHighSeasonTokens = currentSubscription.remainingHighSeasonTokens >= 0 ? currentSubscription.remainingHighSeasonTokens : 0;
        currentSubscription.remainingLowSeasonTokens = currentSubscription.remainingLowSeasonTokens >= 0 ? currentSubscription.remainingLowSeasonTokens : 0;
      } else {
        currentSubscription.accreditations.map(acc => {
          if (acc.creditsUnit == 'HOUR') {
            let hours = Math.trunc(acc.remainingCredits);
            let minutes = Math.round((acc.remainingCredits - hours) * 60);
            acc.remainingTime = `${hours} H ${String(minutes).padStart(2, '0')}`;
          } else {
            acc.remainingTime = acc.remainingCredits;
          }
        })
      }

      return currentSubscription;
  }

  public async getOnboardingSubscription() {

      let currentSubscription = await this.http.get<any>(`${environment.baseUrl}/club-members/subscriptions/on-boarding`).toPromise();
      if (currentSubscription.subscriptionType != 'LPV2') {
        currentSubscription.remainingHighSeasonCredits = currentSubscription.remainingHighSeasonCredits >= 0 ? currentSubscription.remainingHighSeasonCredits : 0;
        currentSubscription.remainingLowSeasonCredits = currentSubscription.remainingLowSeasonCredits >= 0 ? currentSubscription.remainingLowSeasonCredits : 0;
        currentSubscription.remainingHighSeasonTokens = currentSubscription.remainingHighSeasonTokens >= 0 ? currentSubscription.remainingHighSeasonTokens : 0;
        currentSubscription.remainingLowSeasonTokens = currentSubscription.remainingLowSeasonTokens >= 0 ? currentSubscription.remainingLowSeasonTokens : 0;
      } else {
        currentSubscription.accreditations.map(acc => {
          if (acc.creditsUnit == 'HOUR') {
            let hours = Math.trunc(acc.remainingCredits);
            let minutes = Math.round((acc.remainingCredits - hours) * 60);
            acc.remainingTime = `${hours} H ${String(minutes).padStart(2, '0')}`;
          } else {
            acc.remainingTime = acc.remainingCredits;
          }
        })
      }

      return currentSubscription;
  }

  public async getAllValidSubscription(): Promise<any> {

      let allSubscription = await this.http.get<any>(`${environment.baseUrl}/club-members/subscriptions`).toPromise();
      let validSubscriptions = [];
      for (const currentSubscription of allSubscription) {
        if (currentSubscription.subscriptionType != 'LPV2') {
          currentSubscription.remainingHighSeasonCredits = currentSubscription.remainingHighSeasonCredits >= 0 ? currentSubscription.remainingHighSeasonCredits : 0;
          currentSubscription.remainingLowSeasonCredits = currentSubscription.remainingLowSeasonCredits >= 0 ? currentSubscription.remainingLowSeasonCredits : 0;
          currentSubscription.remainingHighSeasonTokens = currentSubscription.remainingHighSeasonTokens >= 0 ? currentSubscription.remainingHighSeasonTokens : 0;
          currentSubscription.remainingLowSeasonTokens = currentSubscription.remainingLowSeasonTokens >= 0 ? currentSubscription.remainingLowSeasonTokens : 0;
        } else {
          ( currentSubscription.accreditations || []).map(acc => {
            if (acc.creditsUnit == 'HOUR') {
              let hours = Math.trunc(acc.remainingCredits);
              let minutes = Math.round((acc.remainingCredits - hours) * 60);
              acc.remainingTime = `${hours} H ${String(minutes).padStart(2, '0')}`;
            } else {
              acc.remainingTime = acc.remainingCredits;
            }
          })
        }
        validSubscriptions.push(currentSubscription)
      }
      return validSubscriptions;

  }

  public askNewPassword(email: string) {
    return this.http.post(`${environment.baseUrl}/club-members/identities/forgot-password`, { email: email }).toPromise();
  }

  public changePassword(currentPassword: string, newPassword: string) {
    return this.http.post(`${environment.baseUrl}/club-members/identities/change-password`, { currentPassword, newPassword }).toPromise();
  }

  public fetchSubscriptionsInvoices(): Promise<any>{
    return this.http.get(`${environment.baseUrl}/club-members/identities/subscriptions-invoices`).toPromise();
  }

}
