import { ErrorHandler, Injectable } from "@angular/core";
import { ErrorLoggingService } from "./error-logging.service";
import { Router } from "@angular/router";

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler implements ErrorHandler {

  constructor(
    private errorLoggingService: ErrorLoggingService
  ) { }

  async handleError(error: any) {
    if(error.message.includes('NG04002')) return;

    let payload = {
      message: error.message,
      stack: error.stack,
      url: document?.location?.href
    };
    
    this.errorLoggingService.logError('Unhandled Error', payload).subscribe();
    console.error('Une erreur s\'est produite:', error);
  }

}