<div
  id="mask"
  [ngClass]="{
    hidden: !isLoading(),
    visible: isLoading()
  }"
>
  <div id="spinner" class="spinner-border text-info" role="status">
    <span class="sr-only"></span>
  </div>
</div>
