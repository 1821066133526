<div class="bannerSeparation" (click)="clickCalled.emit()">
    
    <div class="icon-validation" *ngIf="withValidationIcon" [ngClass]="{'success':isValid}">
        <fa-icon [icon]="['fas', 'question-circle' ]" *ngIf="!isValid" ></fa-icon>
        <fa-icon [icon]="['fas', 'check-circle' ]" *ngIf="isValid" ></fa-icon>
    </div>

    <div class="icon-start" *ngIf="faIconStart">
        <fa-icon [icon]="['fas', faIconStart ]" ></fa-icon>
    </div>
    <div class="text" *ngIf="value">
        {{value | translate}}
    </div>
    
    <div class="icon-end" *ngIf="faIconEnd">
        <fa-icon [icon]="['fas', faIconEnd ]" ></fa-icon>
    </div>
</div>