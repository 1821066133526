import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ChatApiService } from './api/chat-api.service';
import { AmityService } from './services/amity/Amity.service';
import { AuthenticationService } from './services/authentication.service';
import { LocalisationLanguageService } from './services/localisation-language.service';
import { LoaderService } from './shared/components/loader/loader.service';
import { NotificationService } from './shared/components/notification/notification.service';
import { CreateBookingService } from './shared/components/trip-selector-panel/CreateBooking.service';
import { default as packageJson } from 'src/../package.json';
import { NavigationError, Router } from '@angular/router';
import { filter } from 'rxjs';
import { ErrorLoggingService } from './services/error-logging.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  @ViewChild('tripSelector') public tripSelector: ElementRef;
  tripSelectorModal: NgbModalRef;

  title = 'nc-liberty-app';

  translations: string[];
  updateInformation: string;
  updateAvailable: boolean;
  version: string;
  domainName: string;

  organisation: any;
  boatClub: any;
  currentUser: any;

  constructor(
    private localisationLanguageService: LocalisationLanguageService,
    private notificationService: NotificationService,
    private loaderService: LoaderService,
    private http: HttpClient,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private amityService: AmityService,
    private createBookingService: CreateBookingService,
    private modalService: NgbModal,
    private router: Router,
    private errorLoggingService: ErrorLoggingService
  ) {
    this.domainName = document.location.host.replace(/\./g, '-').replace(/:/g, '_');
  }

  async ngOnInit() {

    try {
      let isAuth = this.authenticationService.isUserAuthenticated();
      if (isAuth) {
        this.currentUser = await this.authenticationService.getCurrentUser(true);
        if (this.currentUser) { // Not logged-in
          this.organisation = this.currentUser.ORGANISATION;
          this.boatClub = this.currentUser.BOAT_CLUB;
          this.version = packageJson.version;
        }
      }
    } catch (e) {
      console.error(e);
    }

    this.router.events
      .pipe(
        filter((event: any) => event instanceof NavigationError)
      )
      .subscribe(async (event: NavigationError) => {
        const payload = {
          message: event.error.message,
          stack: event.error.stack,
          url: document?.location?.href,
          targetUrl: event.url
        }
        await this.errorLoggingService.logError('NG04002', payload).toPromise();
      });

    this.translations = this.translate.instant('pwa.updateNewVersionInformation');

    try {
      let res = await this.http.get('./ngsw.json').toPromise();
      this.version = res['appData'].version;
    } catch (e) {
      //console.log(e);
    }

    this.localisationLanguageService.initLanguages();

    this.initChatService();
    this.initAddBookingBehavior()

  }

  reload() {
    document.location.reload();
  }

  async checkUpdateForced() {
    this.loaderService.showLoader();

    try {
      let res = await this.http.get('./ngsw.json').toPromise();
      let version = res['appData'].version;
      if (version != this.version) {
        this.forceUpdateNotif(version);
      }
    } catch (e) {
      //console.log(e);
    } finally {
      this.loaderService.hideLoader();
    }
  }

  forceUpdateNotif(newVersion) {
    this.translations = this.translate.instant(['pwa.updateNewVersionInformation']);
    let info = this.translations['pwa.updateNewVersionInformation'] + ` (${newVersion})`;
    this.updateAvailable = true;
    this.updateInformation = info;
  }

  initChatService() {

    // Subscribe Login Logoff
    this.authenticationService.userAuthenticatedChanged.subscribe(async (loggedIn) => {
      // console.log(`[appComponent.initChatService] loggedIn=${loggedIn}`)
      if (loggedIn) {
        try {
          // let chatConfig = await this.chatApiService.getChatConfig();
          // if (chatConfig) {
          //   await this.amityService.login(chatConfig);
          // }
        } catch (error) {

        }
      } else {
        this.amityService.logoff();
      }
    })

    this.authenticationService.notifyLoggedInStatus();

  }

  initAddBookingBehavior() {

    this.createBookingService.modalStatusChanged$.subscribe((display: boolean) => {
      if (display) {
        this.tripSelectorModal = this.modalService.open(this.tripSelector, { windowClass: 'modal-full', animation: false });
      } else {
        if (this.tripSelectorModal) {
          this.tripSelectorModal.close();
        }
      }
    })
  }

}
