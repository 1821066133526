import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private _isLoading = false;
  private _spinnersStack: string[] = [];

  showLoader(): void {
    this._spinnersStack.push('');
    this._isLoading = Boolean(this._spinnersStack.length);
  }

  hideLoader(): void {
    if (this._spinnersStack.length) {
      this._spinnersStack.pop();
    }
    if (!this._spinnersStack.length) {
      this._isLoading = false;
    }
  }

  get isLoading(): boolean {
    return this._isLoading;
  }
}
