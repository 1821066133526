import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faChevronLeft, faChevronRight, faSave, faStethoscope, faCamera, faChevronDown, faTimesCircle, faBan, faBars, faHome, faCalendar, faClock, faHourglassHalf, faHistory, faUser, faDownload, faTrashAlt, faQrcode, faShip, faMapMarkerAlt, faExclamationCircle, faExternalLinkAlt, faExternalLinkSquareAlt, faEye, faClipboardCheck, faSun, faSnowflake, faExclamationTriangle, faEllipsisV, faPlus, faTimes, faEnvelope, faGlobe, faPhoneSquareAlt, faStar, faFrownOpen, faSmile, faKey, faArrowAltCircleLeft, faInfinity, faCheckCircle, faLongArrowAltRight, faInfoCircle, faAward, faTools, faEdit, faCheck, faThumbsUp, faCalendarPlus, faAngleRight, faPaperPlane, faComment, faListAlt, faList, faArrowRight, faSignOutAlt, faFile, faFileAlt, faSync, faRocket, faChevronUp, faCircleCheck, faQuestionCircle, faArrowsRotate, faSquare, faSquareCheck, faPlay, faUpload, faFileImage, faImage, faThumbsDown, faComments, faCircle } from '@fortawesome/free-solid-svg-icons';
import { PwaInstallModalComponent } from './components/pwa-install-modal/pwa-install-modal.component';
import { faTwitter, faFacebookF, faLinkedinIn, faVimeo, faYoutube, faDailymotion } from '@fortawesome/free-brands-svg-icons';
import { BannerSectionComponent } from './components/banner-section/banner-section.component';
import { TripSelectorPanelComponent } from './components/trip-selector-panel/trip-selector-panel.component';
import { ChatComponent } from './components/chat/chat.component';
import { MessageFormComponent } from './components/chat/message-form/message-form.component';
import { ChannelBrowserComponent } from './components/chat/channel-browser/channel-browser.component';
import { MessageItemComponent } from './components/chat/message-item/message-item.component';
import { ChatRoomComponent } from './components/chat/chat-room/chat-room.component';
import { ChannelBrowserItemComponent } from './components/chat/channel-browser-item/channel-browser-item.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UpdateVersionModalComponent } from './components/update-version-modal/update-version-modal.component';

@NgModule({
  declarations: [
    PwaInstallModalComponent,
    BannerSectionComponent,
    TripSelectorPanelComponent,
    ChatComponent,
    MessageFormComponent,
    ChannelBrowserComponent,
    ChatRoomComponent,
    MessageItemComponent,
    ChannelBrowserItemComponent,
    LanguageSelectorComponent,
    UpdateVersionModalComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    NgbModule
  ],
  exports: [
    TranslateModule,
    FontAwesomeModule,
    PwaInstallModalComponent,
    BannerSectionComponent,
    TripSelectorPanelComponent,
    ChatComponent,
    MessageFormComponent,
    ChannelBrowserComponent,
    ChatRoomComponent,
    MessageItemComponent,
    ChannelBrowserItemComponent,
    LanguageSelectorComponent,
    UpdateVersionModalComponent
  ]
})

export class SharedModule {

  constructor(private library: FaIconLibrary) {
    this.library.addIcons(faChevronLeft,
      faChevronRight,
      faSave,
      faStethoscope,
      faCamera,
      faChevronDown,
      faTimesCircle,
      faBan,
      faBars,
      faHome,
      faCalendar,
      faClock,
      faHourglassHalf,
      faHistory,
      faUser,
      faDownload,
      faTrashAlt,
      faQrcode,
      faShip,
      faMapMarkerAlt,
      faExclamationCircle,
      faExternalLinkAlt,
      faExternalLinkSquareAlt,
      faEye,
      faClipboardCheck,
      faSun,
      faSnowflake,
      faExclamationTriangle,
      faEllipsisV,
      faPlus,
      faTimes,
      faEnvelope,
      faGlobe,
      faPhoneSquareAlt,
      faStar,
      faFrownOpen,
      faSmile,
      faKey,
      faArrowAltCircleLeft,
      faInfinity,
      faCheckCircle,
      faLongArrowAltRight,
      faInfoCircle,
      faAward,
      faTools,
      faEdit,
      faCamera,
      faTwitter,
      faFacebookF,
      faLinkedinIn,
      faCheck,
      faThumbsUp,
      faCalendarPlus,
      faAngleRight,
      faVimeo,
      faYoutube,
      faDailymotion,
      faPaperPlane,
      faComment,
      faListAlt,
      faList,
      faArrowRight,
      faSignOutAlt,
      faFile,
      faFileAlt,
      faSync,
      faRocket,
      faChevronDown,
      faChevronUp,
      faCircleCheck,
      faQuestionCircle,
      faArrowsRotate,
      faSquare,
      faSquareCheck,
      faPlay,
      faCamera,
      faUpload,
      faFileImage,
      faImage,
      faThumbsDown,
      faComments,
      faCircle
    );

  }

}
