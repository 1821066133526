<div class="d-flex justify-content-center">
  <div
    [translate]="getNotificationText()"
    [translateParams]="{ reason: getNotificationReason() }"
    id="notification"
    class="alert"
    [ngClass]="getNotificationStyle()"
    role="alert"
  ></div>
</div>
