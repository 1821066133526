// error-logging.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ErrorLoggingService {
  private apiUrl = `${environment.baseUrl}/club-members/app-errors`;

  constructor(private http: HttpClient) {}

  logError(type: string, details: any): Observable<any> {
    const errorLog = {
      type,
      details,
      timestamp: new Date().toISOString(),
      userAgent: navigator.userAgent
    };

    return this.http.post(this.apiUrl, errorLog);
  }

  getStoredErrors(): any[] {
    return JSON.parse(localStorage.getItem('errorLogs') || '[]');
  }

  storeError(errorLog: any): void {
    const errors = this.getStoredErrors();
    errors.push(errorLog);
    localStorage.setItem('errorLogs', JSON.stringify(errors));
  }

  sendStoredErrors(): void {
    const errors = this.getStoredErrors();
    errors.forEach(errorLog => {
      this.http.post(this.apiUrl, errorLog).subscribe(
        () => {
          // Supprimez l'erreur du stockage local si l'envoi réussit
          this.removeErrorFromStorage(errorLog);
        },
        error => {
          console.error('Échec de l\'envoi de l\'erreur à l\'API:', error);
        }
      );
    });
  }

  private removeErrorFromStorage(errorLog: any): void {
    let errors = this.getStoredErrors();
    errors = errors.filter(e => e !== errorLog);
    localStorage.setItem('errorLogs', JSON.stringify(errors));
  }
}