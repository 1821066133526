import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-web-storage';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthenticationService } from './authentication.service';

const PWA_PROMPT_KEY = 'stop-pwa-prompt';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  $showInstallButton: Subject<boolean> = new Subject();
  $showIPhoneInstallMessage: BehaviorSubject<boolean> = new BehaviorSubject(false);
  $showIPadInstallMessage: BehaviorSubject<boolean> = new BehaviorSubject(false)
  displayMode: displayModeEnum;
  stopPwaPrompt: boolean = false;
  deferredPrompt: any;
  userLogguedIn: boolean;

  constructor(
    private localStorageService: LocalStorageService,
    private authenticationService: AuthenticationService
  ) { }

  setup() {
    this.userLogguedIn = this.authenticationService.isUserAuthenticated();
    this.authenticationService.userAuthenticatedChanged.subscribe(v => this.newLogInEvent(v));

    this.stopPwaPrompt = !!this.localStorageService.get(PWA_PROMPT_KEY);
    this.displayMode = this.getPWADisplayMode();
    let showIphoneInstallMessage: boolean = false;
    let showIpadInstallMessage: boolean = false;

    if (this.isIphoneIos() && this.displayMode == displayModeEnum.browser && this.userLogguedIn) {
      this.$showIPhoneInstallMessage.next(true);
      showIphoneInstallMessage = true;
    }

    if (this.isIpadIos() && this.displayMode == displayModeEnum.browser && this.userLogguedIn) {
      this.$showIPadInstallMessage.next(true);
      showIpadInstallMessage = true;
    }

    return { showIphoneInstallMessage, showIpadInstallMessage };
  }

  beforeInstallPrompt(ev: Event) {
    ev.preventDefault();
    this.deferredPrompt = ev;
    // console.log(`detection possibilité d'installation -> evenement : window:beforeinstallprompt (android)`);
    // console.log(`remplacement du comportement par défaut  (android)`);

    if (this.userLogguedIn && !this.stopPwaPrompt) {
      this.$showInstallButton.next(true);
    }
  }

  newLogInEvent(v) {
    this.userLogguedIn = v;

    if (!this.deferredPrompt) return;

    if (this.userLogguedIn == false) {
      this.$showInstallButton.next(false);
      // this.$showIphoneInstallMessage.next(false);
      return;
    }

    if (this.userLogguedIn && !this.stopPwaPrompt) {
      window.location.reload();
      // this.$showInstallButton.next(true);

      // if (this.isIphoneIos() && this.displayMode == displayModeEnum.browser) {
      //   this.$showIphoneInstallMessage.next(true);
      // }

      // if (this.isIpadIos() && this.displayMode == displayModeEnum.browser) {
      //   this.$showIPadInstallMessage.next(true);
      // }
    }
  }

  appInstalled(ev: Event) {
    console.log(`detection succès d'installation -> evenement : window:appinstalled (android)`);
    console.log(`message de confirmation  (android)`);
    this.stopPwaPrompt = true;
  }

  removePwaPrompt() {
    this.localStorageService.set(PWA_PROMPT_KEY, true);
    this.stopPwaPrompt = true;
  }

  getPWADisplayMode(): displayModeEnum {
    const isAndroidApp = document.referrer.startsWith('android-app://');
    if (isAndroidApp) {
      return displayModeEnum.pwa;
    }

    const isStandaloneInDisplayMode = window.matchMedia('(display-mode: standalone)').matches;
    const isStandAloneInNavigator = window.navigator['standalone'] && ('standalone' in window.navigator);
    if (isStandAloneInNavigator || isStandaloneInDisplayMode) {
      return displayModeEnum.standalone;
    }
    return displayModeEnum.browser;
  }

  public hideIPhonePrompt() {
    this.$showIPhoneInstallMessage.next(false);
  }

  public hideIPadPrompt() {
    this.$showIPadInstallMessage.next(false);
  }

  installApp() {
    this.deferredPrompt.prompt();
    this.deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        this.$showInstallButton.next(false);
      }
      this.deferredPrompt = null;
    });
  }

  isIphoneIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone/.test(userAgent);
  }

  isAndroid = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /android/.test(userAgent);
  }

  isIpadIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /ipad/.test(userAgent);
  }
}


export enum displayModeEnum {
  browser,
  pwa,
  standalone
}