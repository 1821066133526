import { Component } from '@angular/core';
import { NotificationService } from './notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {
  constructor(private notificationStoreService: NotificationService) {}

  getNotificationStyle(): string {
    const type = `alert-${this.notificationStoreService.notification.type}`;
    switch (this.notificationStoreService.isDisplayed) {
      case true:
        return `${type} visible`;
      default:
        return `${type} hidden`;
    }
  }

  getNotificationText(): string {
    return this.notificationStoreService.notification.text;
  }

  getNotificationReason(): string {
    return this.notificationStoreService.notification.reason;
  }
}
